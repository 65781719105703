import React from "react";
import Navbar from "./Navbar";

function Contact() {
  return (
    <> 
    <Navbar/>

    <div className="row g-4 p-3 mx-0">
    {/* Contact Information */}
    <div className="col-md-6">
      <div className="p-4 bg-white rounded shadow-sm">
        <h2 className="text-center mb-4">İletişim</h2>
        {/* Çalışma Saatleri */}
        <div className="shadow-lg rounded-lg p-4 bg-light text-dark mb-3">
          <h4 className="text-primary pb-2">Çalışma Saatleri</h4>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th className="px-2 py-1">Gün</th>
                  <th className="px-2 py-1">Saat</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-2 py-1">Pazartesi - Cuma</td>
                  <td className="px-2 py-1">08:00 - 21:00</td>
                </tr>
                <tr>
                  <td className="px-2 py-1">Cumartesi</td>
                  <td className="px-2 py-1">08:00 - 21:00</td>
                </tr>
                <tr>
                  <td className="px-2 py-1">Pazar</td>
                  <td className="px-2 py-1">Kapalı</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        {/* Telefon */}
        <div className="mb-4">
          <h4 className="text-primary">Telefon</h4>
          <p className="text-muted">0 532 068 81 61</p>
        </div>
  
        {/* Adres */}
        <div className="mb-4">
          <h4 className="text-primary">Adres</h4>
          <p className="text-muted">
            Güzelbahçe, Yusuf Berber, 4. Düzce Bulvarı no:25/A, 81630 Düzce
            Merkez/Düzce
          </p>
        </div>
  
        {/* Sosyal Medya */}
        <div className="mb-4">
          <h4 className="text-primary">Bizi takip edin</h4>
          <div className="d-flex gap-3">
            <i className="bi bi-facebook fs-4 text-primary"></i>
            <i className="bi bi-instagram fs-4 text-danger"></i>
            <i className="bi bi-twitter fs-4 text-info"></i>
          </div>
        </div>
      </div>
    </div>
  
    {/* Google Maps */}
    <div className="col-md-6 ">
      <div className="rounded overflow-hidden shadow-sm ">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d224.2793188852929!2d31.231200443767836!3d40.860224045475896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2str!4v1733166539425!5m2!1str!2str"
          width="100%"
          height="400"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          className="w-100 border "
        ></iframe>
      </div>
    </div>
  </div>
  </>
  );
}

export default Contact;
