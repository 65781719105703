import React from "react";

const About = () => {
  return (
    <>
      {/* Hizmetler Bölümü */}
      <section className="py-10 bg-light">
        <div className="container py-4">
          <div className="text-center mb-2">
            <h2
              className="position-relative d-inline-block display-5 fw-bold"
              style={{
                background: "linear-gradient(to right, #007bff, #6610f2)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              HİZMETLERİMİZ
              <div className="position-absolute start-50 translate-middle mt-2 w-50 border-top border-4 border-primary rounded"></div>
            </h2>

            <p className="text-muted mt-3">
              Sunduğumuz kaliteli hizmetlerle, stilinize değer katıyoruz.
            </p>
          </div>
          <div className="row g-4">
            {/* Kart 1 */}
            <div className="col-md-4">
              <div className="card h-100 shadow-lg text-center">
                <div className="card-body d-flex flex-column">
                  <i className="bi bi-scissors text-primary fs-1 mb-4"></i>
                  <h5 className="card-title fw-bold">Profesyonel Saç Kesimi</h5>
                  <p
                    className="card-text text-dark mt-auto"
                    style={{ fontSize: "1rem" }}
                  >
                    İster klasik, ister modern kesimler... Saçınıza uygun en iyi
                    tarzı yaratıyoruz.
                  </p>
                </div>
              </div>
            </div>
            {/* Kart 2 */}
            <div className="col-md-4">
              <div className="card h-100 shadow-lg text-center">
                <div className="card-body d-flex flex-column">
                  <i className="bi bi-person text-success fs-1 mb-4"></i>
                  <h5 className="card-title fw-bold">Kişiye Özel Hizmet</h5>

                  <p
                    className="card-text text-dark mt-2"
                    style={{ fontSize: "1rem" }}
                  >
                    Her müşteri bizim için özeldir. Sizin stilinizi ön planda
                    tutuyoruz.
                  </p>
                </div>
              </div>
            </div>

            {/* Kart 3 */}
            <div className="col-md-4">
              <div className="card h-100 shadow-lg text-center">
                <div className="card-body d-flex flex-column">
                  <i className="bi bi-heart text-danger fs-1 mb-4"></i>
                  <h5 className="card-title fw-bold">Müşteri Memnuniyeti</h5>

                  <p
                    className="card-text text-dark mt-auto"
                    style={{ fontSize: "1rem" }}
                  >
                    Müşteri memnuniyeti bizim önceliğimizdir. Her zaman en
                    iyisini sunmayı hedefliyoruz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
