import React from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Hakkımızda from "./Hakkımızda";
import Footer from "./Footer";
const Home = () => {
  return (
    <>
      <Navbar />

      <div>
        <Hero />
        <Hakkımızda />
        <Footer />
      </div>
    </>
  );
};

export default Home;
