import React from "react";
import backgroundImage from "../bgpicture.png";

const Hero = () => {
  return (
    <header
    className="bg-dark text-white text-center py-5"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "60vh",
    }}
  >
    <div className="container position-relative d-flex justify-content-center align-items-center h-100">
      <div className="p-4 bg-dark bg-opacity-75 rounded-3 shadow-lg text-center">
        <h1 className="text-white fw-bold">
          Berberinizdeki En İyi Deneyim!
        </h1>
        <p className="lead text-white">
          Modern stil ve kusursuz hizmetle size özel çözümler sunuyoruz.
        </p>
        <a href="/services" className="btn btn-primary mt-3">
          Hizmetlerimizi Keşfedin
        </a>
      </div>
    </div>
  </header>
  
  
  );
};

export default Hero;
