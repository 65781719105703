import React from "react";
import CustomNavbar from "../../src/components/Navbar";

// Dinamik hizmetler listesi
const hizmetler = [
  {
    title: "Modern Kesim",
    description: "Bu model modern bir görünüm isteyenler için ideal.",
    image: "/traşlar/model1.jpeg",
  },
  {
    title: "Klasik Tarz",
    description: "Klasik ve tarz bir görünüm.",
    image: "/traşlar/bıyıktrasi.jpg",
  },
  {
    title: "Trend Fade",
    description: "Yeni nesil bir stil ile trendi yakalayın.",
    image: "/traşlar/model4.jpeg",
  },
  {
    title: "Dağınık Kesim",
    description: "Modern ve asi bir tarz için mükemmel seçim.",
    image: "/traşlar/sactrasi.jpg",
  },
  {
    title: "Keskin Hatlar",
    description: "Daha net ve belirgin bir görünüm.",
    image: "/traşlar/model6.jpeg",
  },
];

const Services = () => {
  return (
    <>
      <CustomNavbar />
      <div className="container mt-3">
        <h2 className="text-center mb-4 fw-bold text-secondary">Saç Modelleri</h2>
        <div className="row">
          {hizmetler.map((hizmet, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="card shadow-sm border-0 rounded-3">
                <img
                  src={hizmet.image}
                  className="card-img-top rounded-top"
                  alt={hizmet.title}
                  style={{
                    height: "300px", // Sabit yükseklik
                    objectFit: "cover", // Görüntüyü orantılı kes
                  }}
                />
                <div className="card-body text-center bg-light">
                  <h5 className="card-title text-uppercase text-dark fw-bold">
                    {hizmet.title}
                  </h5>
                  <p className="card-text text-muted">{hizmet.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Services;
