import React from "react";
import { NavLink } from "react-router-dom";

const CustomNavbar = () => {
  return (
    <nav
      className="navbar navbar-expand-md py-3"
      style={{
        background: "linear-gradient(45deg,rgb(11, 46, 80), #4f8fd1)",
      }}
    >
      <div className="container ">
        <NavLink
          className="navbar-brand text-light"
          to="/"
          style={{
            fontSize: "1.3rem",  
          }}
        >
          💈 Berberim
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav text-end ms-auto">
            <li className="nav-item px-2">
              <NavLink className="nav-link text-light" to="/" end>
                Ana Sayfa
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-light" to="/services">
                Saç Modelleri
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-light" to="/contact">
                İletişim
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default CustomNavbar;
