import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <div className="container text-center">
        <p>© 2024 Berberim. Tüm Hakları Saklıdır.</p>
        <a href="https://instagram.com" className="text-white me-3">
        <i className="fab fa-instagram me-2"></i>  Instagram
        </a>
        <a href="https://facebook.com" className="text-white">
        <i className="fab fa-whatsapp  me-2"></i>    Facebook
        </a>
      </div>
    </footer>
  );
};

export default Footer;
